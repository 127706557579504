<template>
<div>
  <search-field title="条件筛选">
    <el-form :inline="true" size="small" label-width="100px">
      <el-form-item label="注册时间">
        <div class="block">
          <el-date-picker
            clearable
            v-model="search.searchFields.register_time_start"
            placeholder="请选择"
            type="datetime"
            value-format="timestamp"
            style="margin-right:10px"
          ></el-date-picker>
          至
          <el-date-picker
            clearable
            placeholder="请选择"
            v-model="search.searchFields.register_time_end"
            type="datetime"
            value-format="timestamp"
            style="margin-left:10px"
          ></el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="审核状态">
        <el-select clearable v-model="search.searchFields.check_status" placeholder="请选择">
          <el-option
            v-for="item in check_status_list"
            :key="item.code"
            :label="item.value"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="查询条件" required >
        <el-row class="block">
         <el-col :span="6">
          <el-select v-model="search.searchFields.type" placeholder="请选择">
            <el-option
              v-for="(item) in typeList"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
          </el-col>
          <el-col :span="12">
            <el-input clearable v-model="search.searchFields.mobile" placeholder="请输入学员手机号"></el-input>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <div class="search-button-container">
      <el-button type="primary" size="small" @click="getBaList(1)">查询</el-button>
      <el-button size="small"  @click="getBaList(1, true)" plain>导出</el-button>
    </div>
  </search-field>
  <div class="page-content">
    <div class="button-field">
      <el-button type="primary" size="small" @click="handleBatch('refuse')">批量拒绝</el-button>
      <el-button type="primary" size="small" @click="handleBatch('pass')">批量通过</el-button>
    </div>
    <v-table
      :selection="true"
      :data="options.data"
      :columns="options.columns"
      :isBackPage="true"
      :totalCount="options.totalCount"
      :defaultcurrentPage="search.searchFields.page"
      @handleCurrentChange="handlePageChange"
      @handleSizeChange="handleSizeChange"
    >
    </v-table>
  </div>
</div>
</template>
<script>

import vTable from '@/components/tools/vtable'
import searchField from '@/components/tools/searchField'
import { BAApi } from '@/api'
import { formatDate } from '@/libs/utils'

export default {
  data() {
    return {
      options: {
        columns: [
          {
            label: '手机号',
            key: 'mobile'
          },
          {
            label: '姓名',
            key: 'name'
          },
          {
            label: '工号',
            key: 'job_number'
          },
          {
            label: '门店编号',
            key: 'shop_number'
          },
          {
            label: '门店名称',
            key: 'shop_name'
          },
          {
            label: '注册时间',
            key: 'create_time'
          },
          {
            label: '状态',
            type: 'render',
            render: (h, { row }) => {
              return (
                <div>
                  <div class="status-td">
                    {
                      <div><span class={`tag tag${Number(row.check_status)}`}></span>{this.checkStatus(row.check_status)}</div>
                    }
                  </div>
                </div>
              )
            }
          }
        ],
        data: [],
        totalCount: 0,
        loading: false,
      },
      search: {
        searchFields: {
          page: 1,
          count: 20,
          register_time_start: '',
          register_time_end: '',
          check_status: '',
          mobile: '',
          type: 0
        }
      },
      typeList: [
        {
          code: 0,
          value: '手机号'
        },
      ],
      check_status_list: [
        {
          code: 0,
          value: '全部'
        },
        {
          code: 1,
          value: '审核中'
        },
        {
          code: 2,
          value: '审核通过'
        },
        {
          code: 3,
          value: '审核拒绝'
        }
      ],
      updateParams: {}
    }
  },

  methods: {
    checkStatus: value => {
      switch (Number(value)) {
        case -1:
          return '审核作废'
        case 1:
          return '待审核'
        case 2:
          return '审核通过'
        case 3:
          return '审核拒绝'
        case 4:
          return '小程序已绑定'
        default:
          break
      }
    },
    // 后端分页
    handlePageChange(page) {
      this.search.searchFields.page = page
      this.getBaList()
    },
    handleSizeChange(count) {
      this.search.searchFields.count = count
      this.getBaList(1)
    },
    async getBaList(page, isExport) {
      this.search.searchFields.page = page || this.search.searchFields.page
      let params = {...this.search.searchFields}
      if (this.search.searchFields.register_time_start) {
        params.register_time_start = formatDate(Number(this.search.searchFields.register_time_start), 'YYYY-MM-DD HH:mm:ss').dateString
      }
      if (this.search.searchFields.register_time_end) {
        params.register_time_end = formatDate(Number(this.search.searchFields.register_time_end), 'YYYY-MM-DD HH:mm:ss').dateString
      }
      if (!isExport) {
        try {
          let data = (await BAApi.getBaList(params)).data
          if (data.code === 0) {
            this.options.data = data.data.apply_list
            this.options.totalCount = parseInt(data.data.total_count) || 0
          } else {
            this.$error(data)
          }
        } catch (err) {
          console.error(err)
        }
      } else {
        params.page = ''
        params.count = ''
        this.importList(params)
      }
    },
    clearSearch() {
      this.search = {
        searchFields: Object.assign({ count: this.search.searchFields.count }, {
          page: 1,
          register_time_start: '',
          register_time_end: '',
          check_status: '',
          mobile: '',
          type: ''
        })
      }
    },
    handleBatch(type) {
      let data = this.$store.state.Table.rowAction
      if (data.length > 0) {
        let ids = data.map(item => item.id)
        this.updateParams.ids = ids.join()
        this.updateParams.check_status = type === 'pass' ? 2 : 3
        let tip = `确定全部审核${type !== 'pass' ? '不':''}通过？`
        return this.$confirm(tip, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.changeStatus()
        })
      } else {
        this.$message.error('请先进行选择')
        return
      }
    },
    async changeStatus() {
      let loading = this.$loading()
      try {
        let data = (await BAApi.updateStatus(this.updateParams)).data
        if (data.code === 0) {
          this.$message.success('审核成功')
          this.getBaList()
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    async importList(params) {
      try {
        let data = (await BAApi.exportList(params)).data
        if (data.code === 0) {
          let time = formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss').dateString
          let downLink = document.createElement('a')
          downLink.href = data.data.file_path
          downLink.download = `${time}-BA列表`
          downLink.click()
        } else {
          this.$error(data)
          return
        }
      } catch (error) {
        console.log(error)
      }
    }
  },

  mounted() {
    this.getBaList()
  },
  components: {
    vTable, searchField
  }
}
</script>

<style lang="less">
.status-td {
  display: flex;
  align-items: center;
  justify-content: center;
  .tag {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .tag-1 {
    background-color: #e6a23c;
  }
  .tag1 {
    background-color: #409EFF;
  }
  .tag2 {
    background-color: #67C23A;
  }
  .tag3 {
    background-color: #F56C6C;
  }
  .tag4 {
    background-color: #409EFF;
  }
}
</style>
